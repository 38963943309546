body {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 8px 8px 8px 36px;
  min-width: 200px;

  overflow: auto;
}

h1, h2, h3, h4, h5 {
  font-family: 'Sedgwick Ave', sans-serif;

  a {
    text-decoration: none;
  }
}

div#root {
  font-family: 'Verdana', sans-serif;

  height: 100%;
  display: flex;
  flex-direction: column;
}

header {
  #layout_title {
    margin-top: 28px;
    float: left;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;

      #layout_logo {
        margin-right: 0.5em;
      }

      &:visited {
        color: inherit;
      }
    }
  }

  #layout_profile {
    #layout_greeting {
      display: inline-block;
      margin-left: 8px;
      margin-bottom: 8px;
      white-space: nowrap;
    }

    position: absolute;
    right: 10px;
  }

  &::after {
    content: ' ';
    display: block;
    clear: both;
  }
}

.link-button {
  display: inline-block;
  border-radius: 4px;
  text-decoration: none;
  padding: 8px;
  color: black;
  border: 2px solid black;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    background-color: gainsboro;
  }
}

.join-button {
  cursor: pointer;
  border-radius: 4px;
  border: none;
  color: white;
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #63b55d;
  font-size: inherit;
  font-weight: bold;
  vertical-align: baseline;
  padding: 8px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #418241;
  }

  &::before {
    display: inline-block;
    content: 'person_add';
    font-family: 'Material Icons';
    font-size: 20px;
    max-width: 20px;
    max-height: 20px;
    overflow: hidden;
    margin-right: 0.2em;
    vertical-align: bottom;
  }
}

#challenge_details {
  display: flex;
  flex-direction: row;
  padding-left: 0;

  li {
    list-style: '| ';
    margin-left: 1em;
    font-weight: bold;

    .challenge-detail-label {
      font-weight: normal;
      font-style: italic;
      color: #666666;
    }

    &:first-child {
      list-style: none;
      margin-left: 0;
    }
  }
}

.joined-successfully {
  color: #418241;
  font-weight: bold;
}

a.strava-logout, a.strava-login {
  font-family: "MaisonNeue", "Segoe UI", "Helvetica Neue", "system-ui", "BlinkMacSystemFont", "Roboto", "Arial", sans-serif;
  color: white;
  margin-right: 8px;
  border: none;

  &::before {
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 20px;
    max-width: 20px;
    max-height: 20px;
    overflow: hidden;
    margin-right: 0.2em;
    vertical-align: bottom;
  }
}

a.strava-logout {
  background-color: #626262;

  &:hover {
    background-color: #373737;
  }

  &::before {
    content: 'launch';
  }
}

a.strava-login {
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #fc5200;

  &:hover {
    background-color: #cc4200;
  }
}

#layout_main {
  flex-grow: 1;
}

#challenge_title {
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-right: 0.5em;
  }

  a#strava_segment_link {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  .effort-link {
    font-size: small;
    color: dimgray;

    &:visited {
      color: dimgray;
    }

    &:hover {
      color: blue;
    }
  }
}

.route-map-image {
  // There's probably a better way to lay this out.
  float: right;
  margin: 8px;
  width: 30%;
  min-width: 300px;
  height: auto;
}

.main-table-container {
  min-width: 323px;
  display: flex;
  flex-direction: column;
}

.selected-results-display {
  font-weight: bold;
}

a.alternate-results-display {
  color: gray;
  text-decoration: underline;
}

.main-table {
  border-collapse: collapse;

  thead th, tr.table-header-row th {
    background-color: lightgray;
    font-weight: bold;
  }

  td, th {
    border: 1px solid gray;
    padding: 8px;
  }
}

td.kom {
  &::before {
    display: inline-block;
    content: 'star';
    font-family: 'Material Icons';
    font-size: 20px;
    max-width: 20px;
    max-height: 20px;
    overflow: hidden;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: #f3c361;
  }
}

footer {
  padding-top: 8px;
}

// TODO make this use the shared modal control
.window-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.dialog {
  background-color: white;
  box-shadow: 5px 5px 10px black;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  max-width: 100%;
  padding: 16px;
}

.user-detail-row {
  margin-bottom: 24px;

  label {
    margin-right: 16px;
  }

  select, input {
    margin-left: 8px;
    font-size: inherit;
  }
}

.login-options-container, .signup-options-container {
  max-width: 500px;
  position: relative;

  hr {
    margin-top: 2em;
    margin-bottom: 2em;

    &::before {
      content: ' or ';
      font-size: 24px;
      position: absolute;
      margin-top: -16px;
      background-color: white;
      padding: 4px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
}

.login-form, .signup-form {
  padding: 8px;
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    margin-bottom: 8px;

    input {
      flex-grow: 1;
    }
  }

  .birthdate-row {
    label {
      display: inline-block;
    }
  }

  button {
    cursor: pointer;
    align-self: flex-end;
    display: inline-block;
    border-radius: 4px;
    text-decoration: none;
    padding: 8px 24px;
    color: white;
    background-color: cadetblue;
    font-family: inherit;
    font-size: inherit;
    border: none;
  }
}


#save_cancel_user_details {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#save_user_details {
  border-radius: 4px;
  color: white;
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #63b55d;
  vertical-align: baseline;
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: inherit;
  border: none;

  &:disabled {
    background-color: lightgrey;
  }

  &:hover {
    background-color: #418241;
  }
}

.invite-code-dialog {
  input {
    margin-left: 8px;
    font-size: inherit;
    vertical-align: text-bottom;
  }
}

span.material-icons {
  vertical-align: text-bottom;
}

.clickable {
  cursor: pointer;
}

.error {
  color: red;
}

.form-field-description {
  font-size: 80%;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.modal-dialog-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  pointer-events: all;

  .modal-dialog {
    position: relative;
    background-color: white;
    padding: 2em;
    margin-left: 10%;
    margin-right: 10%;
    max-width: 800px;
    margin-top: 50px;

    .modal-dialog-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      cursor: pointer;
    }

    .dialog-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;


      button {
        cursor: pointer;
        border-radius: 4px;
        text-decoration: none;
        padding: 8px 24px;
        font-family: inherit;
        font-size: inherit;
      }

      .cancel-button {
        color: black;
        background-color: white;
        border: 2px solid black;

        &:hover {
          background-color: gainsboro;
        }
      }
    }
  }
}

button:disabled, button[disabled], button:disabled:hover, button[disabled]:hover {
  border-color: lightgrey !important;
  background-color: lightgrey !important;;
  color: white !important;;
}
